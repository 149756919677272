form#contact {  text-align: center; margin:0 auto; display:block; position:relative; @include clearfix; background-color:$green; width:100%; padding:2vh 40px;
		p.message.success { display:none; color:$green; font-size:19px; width:100%;  text-align:center; line-height:30px; }
		fieldset{ 
			legend { color:$white; margin-bottom:2vh; line-height:20px; font-size:14px; width:100%; text-align:center; @extend .bold }
			input, button { @extend .bold;  border-radius:50px; font-size:14px; padding:8px 0;  border:none; }
			input { margin:0 16px 12px 0; width:100%; padding-left:16px; padding-right:16px; }
			button { width:120px; line-height: inherit; font-size:16px; background-color:$blue; color:white; text-transform: uppercase; @include transition(all 0.3s);
				&:hover { background-color:white; color:$blue; }
		}
	}

	@include media($small + px) {
		background-color:transparent; left:0; width:100%;
		fieldset{ margin-bottom: 60px;
			legend { line-height:28px; font-size:16px; font-weight: 300;}
			input, button { float:left; }
			input { width:calc(50% - 60px - 16px); margin-bottom:0;}
			button { background-color:$green; }
			button:hover{ background:$white; transition:ease 0.5s; color:$blue; cursor:pointer; }
		}
	}
}
