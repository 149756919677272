// Introduction
	intro-content{
		display: block;
		text-align: center;
		margin:55px 0;
		h1 { font-size:27px; line-height:36px; margin-bottom:22px; color:$white; @extend .bold; }
		h5 { color:$green; margin-bottom:20px; font-size:16px; @extend .bold; line-height:20px; }
		p { color:$white; line-height:24px; margin-bottom:15px; font-size:16px; @extend .regular;
			&:last-of-type{  @extend .bold; }
		}

	@include media($small + px) {
		intro-content{
			display: block;
			h1 { font-size:32px; }
		}
	}
}


// Navigation Buttons
navigation-buttons{
	display: block;
	margin: 50px 0;
	section.nav-buttons{
		display: block;
		justify-content: space-between;
		position: relative;
		a{
			display:block;
			color: $white;
			background: $green;
			@extend .bold;
			border-radius: 10px;
			position: relative;
			min-height:65px;
			min-width: 222px;
			max-width: 280px;
			margin: 0 auto 15px auto;
			p{
				padding:12px 18px;
				font-size:21px;
				text-align:left;
				padding-left: 75px;
				position: absolute;
		    top: 50%;
		    margin-top: -22.5px;
				&.first{
					top: 0;
			    margin-top: 0;
				}
				svg{
					position: absolute;
					left:18px;
					top:50%;
					margin-top:-22.5px;
					path{
						fill:$white;
					}
				}
			}

		}
		a:hover, a:hover svg path{
			background:$white;
			transition:ease 0.8s;
			color:$blue;
			fill:$blue;
		}
	}
	@include media($medium + px) {
		section.nav-buttons{
			display: flex;
			a{
				flex-basis: 31%;
				margin-bottom: 0;
			}
		}
	}
}
