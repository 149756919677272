cta-bar { display:block; position:relative; margin:5vh 0; width:calc(100% + 8vw); left:-4vw; text-align: center; padding:2vh 2vw;
	p { font-size:30px; color:$blue; font-family:"open-sans", Helvetica, Arial, sans-serif; @extend %font-smooth;
		span { display:none; }
		a { color:white; display:inline-block; line-height:32px; @include transition(opacity 0.3s);
			&:before { content:"t: "; color:$green; }
			&:hover { opacity:0.6; }
			@include media(320px) { display:initial; }
		}
		@include media(500px) { font-size:32px;
			font-family:"open-sans-bold", Helvetica, Arial, sans-serif;
			span { display:inline-block; }
			a:before { display:none; }
		}
	}
	> a { color:white; background-color:$green; border-radius:50px; padding:8px 20px; text-transform: uppercase; margin-top:1vh; display:inline-block; font-size:16px; @include transition(all 0.3s);
		&:hover { background-color:white; color:$blue; }
	}

	@include media(500px) { background-color:$green;
		> a { background-color:$blue; }
	}
	@include media($medium + px) { width:300%; left:-100%; }
}
