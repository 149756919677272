  footer {
    text-align: center;
    ul.logos {
      padding-top:20px;
      margin-bottom: 25px;
      display:flex;
      justify-content:center;
      flex-wrap: wrap;
      li{
        display:block;
        margin: 8px;
        img {
          height:40px;
        }
        a{
          display: block;
        }
      }
    }
    // Footer text
    small {
      color:white;
      text-align:center;
      position:relative;
      display:block;
      font-size:10px;
      p {
        margin-bottom:8px;
        display:block;
        > a {
          color:$green;
          &:hover {
            color:$yello;
          }
        }
      }
    }
    @include media($small + px) {
      ul.logos {
        // display: inline-block;
        li{
        display:inline-block;
        padding:0 12px;

        }
      }
    }
  }
