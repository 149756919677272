// Fees and expenses

// Introduction
main#fees-expenses {
	text-align: center;
	margin-bottom: 58px;
	intro-fees-expenses{
		display: block;
		margin: 50px auto 65px auto;
		h1 { font-size:32px; @extend .bold; margin-bottom:22px; color:$white; }
		h5 { color:$green; margin-bottom:20px; font-size:16px; @extend .bold; }
	}
}
//  Tab Menu
	fees-expenses-menu{
		display:block;
		.tab-menu{
			display:flex;
			position: relative;
			a{
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				width: 50%;
				color:$white;
				background:$blue;
				@extend .bold;
				position:relative;
				min-height:60px;
				padding:15px;

					p{
						font-size: 18px;
						margin-left: 10px;
					}
					svg{
						height:35px;
						width:35px;
						margin-right:0;
						path{
							fill:$white;
						}
					}
					&.active{
						background:$white;
						transition:ease 0.5s;
						color:$blue;
						fill:$blue;
						svg path{
							fill:$blue;
						}
					}
					&:hover, &:hover svg path,{
						background:$white;
						transition:ease 0.5s;
						color:$blue;
						fill:$blue;
					}
			}
		}
		@include media(436px) {
			.tab-menu{
				a{
					p{
						margin-left: 10px;
					}
			}
		}
	}
		@include media(520px) {
			.tab-menu{
				a{
					padding:10px 40px;
					p{
						margin-left: 38px;
					}
					svg{
						// margin-right:15px;
					}
				}
		}
	}
}

	// Menu sections
	fees-expenses-section{
		display: block;
		.landlord-fees, .tenants-fees{
			display:block;
			background:$white;
			text-align:left;
			padding:20px;
			margin-bottom: 50px;
			h3{
				font-size: 20px;
				color:$blue;
				@extend .bold;
				margin:20px 0 15px 0;
				letter-spacing: 0.5px;
				text-transform: uppercase;
			}
			h4{
				font-size: 20px;
				color:$green;
				@extend .bold;
				margin:20px 0 15px 0;
				letter-spacing: 0.5px;
				text-transform: uppercase;
			}
			h5{
				font-size: 16px;
				@extend .bold;
				color:$blue;
				margin-bottom:10px;
				padding-left: 28px;
				position: relative;
			}
			h5:before {
        content: "";
		    display: inline-block;
		    width: 0;
		    height: 0;
		    border-style: solid;
		    border-width: 8.5px 0 8.5px 12px;
		    border-color: transparent transparent transparent #a5ce39;
				position: absolute;
				left:2px;
			}
			h6{
				font-size: 13px;
				line-height: 18px;
				margin-bottom: 10px;
				color:$blue;
			}
			p strong{
				font-size: 16px;
				@extend .bold;
				color:$blue;
				margin-bottom:25px;
			}
			p{
				font-size: 16px;
				line-height: 21px;
				margin-bottom: 10px;
				padding-left: 28px;

			}
		}
		.landlord-fees{
			// display: none;
			h6{
				&:first-of-type{
					font-size: 14px;
					padding-left: 28px;
				}
				&:nth-of-type(3){
					font-size: 14px;
					padding-left: 28px;
					@extend .bold;
				}
			}
			p{
				&:first-of-type, &:nth-of-type(14), &:nth-of-type(15){
					padding-left: 0;

				}
			}
		}
		.tenants-fees{
			h6{
				&:first-of-type{
					font-size: 14px;
					padding-left: 28px;
				}
			}
			p {
				&:first-of-type{
					padding-left: 0;
				}
			}
		}
		@include media($small + px) {
			.landlord-fees, .tenants-fees{
				padding:40px;
			}
		}
	}
