// Fonts
// @include font-face("Bliss Light", "BlissPro/Light/BlissPro-Light", eot otf svg ttf woff);
//

@include font-face("open-sans", "/assets/fonts/OpenSans-Regular", normal,  $file-formats: ttf);
@include font-face("open-sans-italic", "/assets/fonts/OpenSans-Italic", italic,  $file-formats: ttf);
@include font-face("open-sans-bold", "/assets/fonts/OpenSans-Bold", bold,  $file-formats: ttf);
@include font-face("open-sans-bolditalic", "/assets/fonts/OpenSans-BoldItalic", bold, italic,  $file-formats: ttf);

%font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//
.font1, .regular { font-family:"open-sans", Helvetica, Arial, sans-serif; @extend %font-smooth; }
.font2, .italic { font-family:"open-sans-italic", Helvetica, Arial, sans-serif; @extend %font-smooth; }
.font3, .bold { font-family:"open-sans-bold", Helvetica, Arial, sans-serif; @extend %font-smooth; }
.font4, .bolditalic { font-family:"open-sans-bolditalic", Helvetica, Arial, sans-serif; @extend %font-smooth; }
