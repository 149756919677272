@charset "UTF-8";

// Important
@import "base/settings";
@import "base/reset";
@import "base/mixins";
@import "vendors/bourbon/bourbon";
@import "vendors/neat/neat";
@import "vendors/neat/neat-helpers";

// Optional
@import "base/fonts";

// Components
@import "components/header";
@import "components/footer";
@import "components/navigation";
@import "components/form";
@import "components/cta";
@import "components/contact-us";
@import "components/certificates";
// Layouts

// Pages
@import "pages/home";
@import "pages/fees-expenses";

// Global
html, body { min-height:100%; }

body { @extend .font1;
	&.index{
	  background:$blue url('/assets/images/home-background.jpg') center center / cover no-repeat;
	}
	&.fees-expenses{
	  background:$blue url('/assets/images/fees-background.jpg') top center / cover no-repeat;
	}
}

// main-wrapper { padding:4vh 4vw; position:relative; display:block; overflow:hidden; }

// Angular enforce display none to remove flicker on load.
[ng\:cloak], [ng-cloak], .ng-cloak { display: none !important; }

.container{ @include container }

h1{ font-size: 32px; }
