a.contact-us{
	font-size: 30px;
	color:$white;
	display: block;
	margin-bottom: 27px;
	@extend .bold;
	text-align: center;
	span{
		color:$green;
		display: block;
		margin-bottom: 10px;
	}
	@include media($small + px) {
		span{
			display: inline;
			margin-bottom: 0;
		}
	}
}
