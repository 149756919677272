.certificates{
    display: block;
    position: relative;
    text-align: center;
    margin-bottom: 50px;
    .certificate{
        margin-bottom: 32px;
        h5{
            color: #fff;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 15px;
            font-size: 16px;
        }
        a{
            display: table;
            padding: 10px;
            min-width: 200px;
            color: #fff;
            background: #a8cb46;
            border-radius: 30px;
            position: relative;
            margin: 0 auto;
            transition: all 0.3s;
            &:hover{
                color: #a8cb46;
                background: #fff;
            }
        }
    }
}
