header{
	.logo{
		max-width:500px;
		margin:30px auto 0 auto;
		display: block;
		img {
			width:100%;
		}
	}
	@include media($small + px) {
		.logo{
			max-width:500px;
			margin-top:60px; 
		}
	}
}
