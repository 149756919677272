// Colours
$text          : #282C33;
$green         : #a8cb46;
$blue          : #194563;
$selection     : $green;
$yello         : #fec743;

// Neat Colours
$black:         #202020;
$grey:          #3a383a;
$white:         #FFFFFF;

$pink:          #e56284;
$purple:        #711b45;
$beige:         #c7b299;
$lightgrey:     #818285;
$brown:         #736357;

$lightpink:     #eabbc9;

$facebook:      #215B9A;
$twitter:       #00ADF2;
$google:        #CE4435;
$pinterest:     #E51616;

$text-select:   $selection;

$for-her:         #e56284;
$for-him:         #711b45;
$bridal:          #c7b299;
$sun-shower:      #818285;
$electrolysis:    #736357;

$for-her-bg:      #ecdbe1;
$for-him-bg:      #ddd9e1;
$bridal-bg:       #e0d8cf;
$sun-shower-bg:   #ded5d8;
$electrolysis-bg: #e1d4d3;

// Units
$border-radius : 10;
$gaps          : 16;
$font-size     : 16;
$spacer        : 5vh;

$font-sans: 'Arial', sans-serif;
$font-serif: 'Times New Roman', serif;
$font-weight-default: 400;
$font-weight-bold: 900;

// Breakpoints
$min           : 360;
$small         : 480;
$medium        : 800;
$large         : 970;
$max           : 1600;

$column: 90px;
$gutter: 0px;
$grid-columns: 12;
$max-width: $max + px;

// Define your breakpoints
$mobile: new-breakpoint(min-width 480px 12);
$tablet: new-breakpoint(min-width 768px 12);
$sm-desktop: new-breakpoint(min-width $medium + px 12);
$lg-desktop: new-breakpoint(min-width $large + px 12);
$xl-desktop: new-breakpoint(min-width $max + px 12);
